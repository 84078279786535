/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import {
  mdiAccountMultiple,
  mdiVideoOutline,
  mdiTimerOutline,
  mdiShare,
  mdiPercentOutline,
  mdiAccountGroup,
  mdiAccountMultiplePlus,
  mdiCommentAccountOutline,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  getGroupsActive,
  getFacilitiesByGroupMin,
  getDashboardAnalysis,
} from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'

export default function useDashboard() {
  const { updateFilter, getFilterByModule } = useFilters()
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions(true)

  const customOrganization = ref(0)
  const customFacility = ref(0)
  const organizationsOptions = ref([])
  const facilitiesOptions = ref([])

  const timerSearch = ref(null)

  const dateRangeOptions = computed(() => [
    { text: t('date_range.this_year'), value: 'TY' },
    { text: t('date_range.this_month'), value: 'TM' },
    { text: t('date_range.last_month'), value: 'LM' },
    { text: t('date_range.this_week'), value: 'TW' },
    { text: t('date_range.last_week'), value: 'LW' },
    { text: t('date_range.yesterday'), value: 'Y' },
    { text: t('date_range.today'), value: 'T' },
    { text: t('date_range.custom'), value: 'C' },
  ])

  const dateRangeFilter = ref('TM')

  const modalFrom = ref(null)
  const modalTo = ref(null)
  const minDate = ref(
    new Date('2021-01-02').toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const maxDate = ref(
    new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const nowDate = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const dateFrom = ref(nowDate.value)
  const dateTo = ref(nowDate.value)

  // -------------------------------------------------------
  const recordingHoursOptions = ref({
    startTitle: t('statistics.actives_facility'),
    tooltip: 'tooltip',
    icon: mdiTimerOutline,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: true,
  })
  const computedRecordingHoursOptions = computed(() => recordingHoursOptions.value)

  const highlightsCreatedOptions = ref({
    startTitle: t('statistics.actives_field'),
    tooltip: 'tooltip',
    icon: mdiVideoOutline,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: true,
  })
  const computedHighlightsCreatedOptions = computed(() => highlightsCreatedOptions.value)

  const sharesOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiShare,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: true,
  })
  const computedSharesOptions = computed(() => sharesOptions.value)

  const impressionsOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiCommentAccountOutline,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: true,
  })
  const computedImpressionsOptions = computed(() => impressionsOptions.value)

  const occupancyHoursOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiPercentOutline,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedOccupancyHoursOptions = computed(() => occupancyHoursOptions.value)

  const activeUsersOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiAccountMultiple,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedActiveUsersOptions = computed(() => activeUsersOptions.value)

  const newUsersOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiAccountMultiplePlus,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '$0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedNewUsersOptions = computed(() => newUsersOptions.value)

  const totalUsersOptions = ref({
    startTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiAccountGroup,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedTotalUsersOptions = computed(() => totalUsersOptions.value)

  const ageRangeData = ref({
    labels: [],
    data: [],
    items: [],
    total: 0,
  })
  const genderData = ref({
    labels: [],
    data: [],
    items: [],
    total: 0,
  })

  const addPlus = value => {
    if (value > 0) return `+${value}`

    return `${value}`
  }

  const createItems = (chartData, labels) => {
    const data = chartData
    const labelArray = labels
    const items = []

    for (let i = 0; i < data.length; i += 1) {
      const item = { text: labelArray[i], value: data[i] }
      if (!items[Math.floor(i / 2)]) {
        items[Math.floor(i / 2)] = []
      }
      items[Math.floor(i / 2)].push(item)
    }

    return items
  }

  const fetchAnalysis = async (group, facility, range, start, end) => {
    let response = null
    if (group) {
      response = await getDashboardAnalysis(group, facility, range, start, end)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardAnalysis(userData.value.group_id, facility, range, start, end)
    } else {
      response = await getDashboardAnalysis(0, 0, range, start, end)
    }

    if (response.ok) {
      const analytics = response.data

      recordingHoursOptions.value.statistics = analytics.recording_hours
      recordingHoursOptions.value.change = addPlus(analytics.recording_hours_diff)

      highlightsCreatedOptions.value.statistics = analytics.highlights_created
      highlightsCreatedOptions.value.change = addPlus(analytics.highlights_created_diff)

      sharesOptions.value.statistics = analytics.shares
      sharesOptions.value.change = addPlus(analytics.shares_diff)

      impressionsOptions.value.statistics = analytics.views
      impressionsOptions.value.change = addPlus(analytics.views_diff)

      occupancyHoursOptions.value.statistics = analytics.occupancy_hours
      occupancyHoursOptions.value.change = addPlus(analytics.occupancy_hours_diff)

      activeUsersOptions.value.statistics = analytics.active_users
      activeUsersOptions.value.change = addPlus(analytics.active_users_diff)

      newUsersOptions.value.statistics = analytics.new_users
      newUsersOptions.value.change = addPlus(analytics.new_users_diff)

      totalUsersOptions.value.statistics = analytics.total_users

      ageRangeData.value = analytics.age_range
      ageRangeData.value.total = analytics.age_range.data.reduce((total, num) => total + num, 0)
      ageRangeData.value.items = createItems(analytics.age_range.data, analytics.age_range.labels)

      genderData.value = analytics.gender
      genderData.value.total = analytics.gender.data.reduce((total, num) => total + num, 0)
    }
  }

  const fetchSummary = async () => {
    let gruop = 0
    if (userData.value && userData.value.role !== 'A') gruop = userData.value.group_id
    else if (userData.value && userData.value.role === 'A' && customOrganization.value) gruop = customOrganization.value
    else gruop = 0

    await fetchAnalysis(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )
  }

  const fetchFacilitiesByOrganization = async organizationId => {
    customFacility.value = 0
    let resp = null
    let facilities = []
    const all = [{
      id: 0,
      name: 'All Facilities',
    }]

    if (organizationId) {
      resp = await getFacilitiesByGroupMin(organizationId)
    } else if (customOrganization.value) {
      resp = await getFacilitiesByGroupMin(customOrganization.value)
    }

    if (resp && resp.ok) {
      facilities = resp.data
      if (facilities.length === 1) {
        facilitiesOptions.value = facilities
        customFacility.value = facilities.at(0).id
      } else if (facilities.length > 1) {
        facilitiesOptions.value = all.concat(facilities)
        customFacility.value = 0
      }
    } else {
      facilitiesOptions.value = all
    }
  }

  const timerFetch = ref(null)
  const debounceFetch = async (time = 1000) => {
    clearTimeout(timerFetch.value)
    timerFetch.value = setTimeout(async () => {
      await fetchFacilitiesByOrganization(customOrganization.value)
    }, time)
  }

  const fetchOrganizations = async () => {
    const all = [{
      id: 0,
      name: 'All Organizations',
      logo: null,
      facilities: [],
    }]
    const resp = await getGroupsActive()
    if (resp.ok) {
      organizationsOptions.value = all.concat(resp.data)
      customOrganization.value = 0
    } else organizationsOptions.value = all
    customFacility.value = 0
  }

  watch([customFacility, dateRangeFilter, dateFrom, dateTo], async () => {
    if (customFacility.value === null) customFacility.value = 0
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchSummary()
    }, 500)
  })

  watch([customOrganization], async () => {
    if (customOrganization.value === null) customOrganization.value = 0
    clearTimeout(timerSearch.value)
    if (userData.value.role === 'A') await debounceFetch(200)
    timerSearch.value = setTimeout(async () => {
      await fetchSummary()
    }, 500)
  })

  watch([configOrganization], async () => {
    if (userData.value.role !== 'A') customOrganization.value = configOrganization.value
  })

  watch([configFacility], async () => {
    dateRangeFilter.value = 'TM'
    dateFrom.value = null
    dateTo.value = null
    if (userData.value.role !== 'A') customFacility.value = configFacility.value
  })

  onMounted(async () => {
    if (userData.value.role !== 'A') {
      customOrganization.value = configOrganization.value
      customFacility.value = configFacility.value
    } else {
      await fetchOrganizations()
      await debounceFetch(500)
    }

    await fetchSummary()
  })

  return {
    userData,

    dateRangeOptions,
    dateRangeFilter,
    modalFrom,
    modalTo,
    dateFrom,
    dateTo,
    minDate,
    maxDate,
    nowDate,

    computedRecordingHoursOptions,
    computedHighlightsCreatedOptions,
    computedSharesOptions,
    computedImpressionsOptions,
    computedOccupancyHoursOptions,
    computedActiveUsersOptions,
    computedNewUsersOptions,
    computedTotalUsersOptions,
    ageRangeData,
    genderData,

    organizationsOptions,
    facilitiesOptions,
    customOrganization,
    customFacility,
    debounceFetch,
    fetchOrganizations,

    // i18n
    t,

    fetchSummary,
    updateFilter,
    getFilterByModule,
  }
}
