<template>
  <v-card>
    <v-card-title class="align-start">
      <span> {{ title }}</span>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="showBtnDots"
              x-small
              icon
              class="me-n3 mt-n1"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            width="100%"
            height="300px"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiHelpCircleOutline } from '@mdi/js'
import { generateColorShades } from '@core/utils'

export default {
  name: 'StatisticsDonutTableChart',
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    title: {
      type: String,
      default: 'Sales Overview',
      required: false,
    },
    tooltip: {
      type: String,
      default: 'tooltip',
      required: false,
    },
    chartData: {
      type: Array,
      default: () => [],
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
      required: true,
    },
    color: {
      type: String,
      default: '#16B1FF',
      required: false,
    },
    showBtnDots: { type: Boolean, default: true },
  },
  setup(props) {
    const chartOptions = computed(() => {
      const options = {
        labels: props.labels,
        colors: generateColorShades(props.color, 3),
        chart: {
          type: 'donut',
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
              labels: {
                show: true,
                name: {
                  fontSize: '14px',
                  offsetY: 25,
                },
                value: {
                  fontSize: '2.125rem',
                  fontWeight: 600,

                  offsetY: -15,
                  formatter(value) {
                    return `${value}`
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: 'rgba(94, 86, 105, 0.68)',
                  formatter(value) {
                    return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                  },
                },
              },
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
        },
      }

      return options
    })

    return {
      chartOptions,
      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
