<template>
  <div id="dashboard">
    <v-card-title class="px-0 mb-3">
      {{ $t('Dashboard') }}

      <v-spacer></v-spacer>
      <v-btn
        small
        class="white--text"
        color="btnPrimary"
        @click="$router.replace({ name: 'views-reports-view'})"
      >
        {{ $t('tooltip.back') }}
      </v-btn>
    </v-card-title>

    <v-row class="ma-0 text-center">
      <v-col
        cols="12"
        md="2"
        :class="{ 'pl-0': $vuetify.breakpoint.smAndUp }"
      >
        <v-select
          v-model="dateRangeFilter"
          class="bg--white"
          :items="dateRangeOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          :placeholder="$t('select.date_range')"
          @change="updateFilter('views-reports-fanaty', 'custom', dateRangeFilter)"
        ></v-select>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalFrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFrom"
              :label="$t('transactions.from_date')"
              outlined
              readonly
              dense
              class="invoice-list-status bg--white"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            color="secondary"
            :min="minDate"
            :max="nowDate"
            @input="modalFrom = false"
            @change="updateFilter('views-reports-fanaty', 'dateFrom', dateFrom)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalTo"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateTo"
              :label="$t('transactions.to_date')"
              outlined
              readonly
              dense
              class="invoice-list-status bg--white"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            color="secondary"
            :min="dateFrom"
            :max="nowDate"
            @input="modalTo = false"
            @change="updateFilter('views-reports-fanaty', 'dateTo', dateTo)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customOrganization"
          class="bg--white"
          :label="$t('organizations.organization')"
          :placeholder="$t('organizations.organization')"
          :items="organizationsOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          @change="updateFilter('views-reports-fanaty', 'organization', customOrganization)"
        ></v-select>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customFacility"
          class="bg--white"
          :label="$t('facilities.facility')"
          :placeholder="$t('facilities.facility')"
          :items="facilitiesOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="!customOrganization"
          :append-icon="facilitiesOptions.length === 1 || !customOrganization ? null : icons.mdiMenuDown"
          :readonly="facilitiesOptions.length === 1 || !customOrganization"
          :clearable="facilitiesOptions.length > 1"
          @change="updateFilter('views-reports-fanaty', 'facility', customFacility)"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <span
          class="text-body-1 font-weight-medium"
          :style="'color: #989899;'"
        >
          Videos
        </span>
      </v-col>
    </v-row>

    <v-row class="mb-5 match-height">
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedRecordingHoursOptions.change"
          :color="computedRecordingHoursOptions.color"
          :icon="computedRecordingHoursOptions.icon"
          :statistics="computedRecordingHoursOptions.statistics"
          :tooltip="computedRecordingHoursOptions.tooltip"
          :start-title="$t('dashboard.recording_hours')"
          :subtitle="'that last week'"
          :show-btn-dots="computedRecordingHoursOptions.showBtnDots"
          :show-change="computedRecordingHoursOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedHighlightsCreatedOptions.change"
          :color="computedHighlightsCreatedOptions.color"
          :icon="computedHighlightsCreatedOptions.icon"
          :statistics="computedHighlightsCreatedOptions.statistics"
          :tooltip="computedHighlightsCreatedOptions.tooltip"
          :start-title="$t('dashboard.highlights_created')"
          :subtitle="'that last week'"
          :show-btn-dots="computedHighlightsCreatedOptions.showBtnDots"
          :show-change="computedHighlightsCreatedOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedSharesOptions.change"
          :color="computedSharesOptions.color"
          :icon="computedSharesOptions.icon"
          :statistics="computedSharesOptions.statistics"
          :tooltip="computedSharesOptions.tooltip"
          :start-title="$t('dashboard.shares')"
          :subtitle="'that last week'"
          :show-btn-dots="computedSharesOptions.showBtnDots"
          :show-change="computedSharesOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedImpressionsOptions.change"
          :color="computedImpressionsOptions.color"
          :icon="computedImpressionsOptions.icon"
          :statistics="computedImpressionsOptions.statistics"
          :tooltip="computedImpressionsOptions.tooltip"
          :start-title="$t('dashboard.impressions')"
          :subtitle="'that last week'"
          :show-btn-dots="computedImpressionsOptions.showBtnDots"
          :show-change="computedImpressionsOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <span
          class="text-body-1 font-weight-medium"
          :style="'color: #989899;'"
        >
          Customers
        </span>
      </v-col>
    </v-row>

    <v-row class="mb-5 match-height">
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedOccupancyHoursOptions.change"
          :color="computedOccupancyHoursOptions.color"
          :icon="computedOccupancyHoursOptions.icon"
          :statistics="computedOccupancyHoursOptions.statistics"
          :tooltip="computedOccupancyHoursOptions.tooltip"
          :start-title="$t('dashboard.est_occupancy_hours')"
          :subtitle="''"
          :show-btn-dots="computedOccupancyHoursOptions.showBtnDots"
          :show-change="computedOccupancyHoursOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedActiveUsersOptions.change"
          :color="computedActiveUsersOptions.color"
          :icon="computedActiveUsersOptions.icon"
          :statistics="computedActiveUsersOptions.statistics"
          :tooltip="computedActiveUsersOptions.tooltip"
          :start-title="$t('dashboard.active_users')"
          :subtitle="''"
          :show-btn-dots="computedActiveUsersOptions.showBtnDots"
          :show-change="computedActiveUsersOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedNewUsersOptions.change"
          :color="computedNewUsersOptions.color"
          :icon="computedNewUsersOptions.icon"
          :statistics="computedNewUsersOptions.statistics"
          :tooltip="computedNewUsersOptions.tooltip"
          :start-title="$t('dashboard.new_users')"
          :subtitle="''"
          :show-btn-dots="computedNewUsersOptions.showBtnDots"
          :show-change="computedNewUsersOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-vertical-border
          :change="computedTotalUsersOptions.change"
          :color="computedTotalUsersOptions.color"
          :icon="computedTotalUsersOptions.icon"
          :statistics="computedTotalUsersOptions.statistics"
          :tooltip="computedTotalUsersOptions.tooltip"
          :start-title="$t('dashboard.total_users')"
          :subtitle="''"
          :show-btn-dots="computedTotalUsersOptions.showBtnDots"
          :show-change="computedTotalUsersOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <statistics-donut-table-chart
          :title="$t('dashboard.age_range')"
          :tooltip="$t('dashboard.age_range')"
          :chart-data="ageRangeData.data"
          :labels="ageRangeData.labels"
          :table-title="'Total'"
          :total="ageRangeData.total"
          :icon="icons.mdiWalletOutline"
          :color="'#16B1FF'"
          :items="ageRangeData.items"
          :show-btn-dots="true"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <statistics-donut-chart
          :title="$t('dashboard.gender')"
          :tooltip="$t('dashboard.gender')"
          :chart-data="genderData.data"
          :labels="genderData.labels.map(label => t(`users.${label}`))"
          :color="'#16B1FF'"
          :show-btn-dots="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiHelp, mdiWalletOutline } from '@mdi/js'
import { computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCurrency, formatStdDate } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'

import StatisticsCardVerticalBorder from '@/@core/components/statistics-card/StatisticsCardVerticalBorder.vue'
import StatisticsDonutTableChart from '@/@core/components/statistics-card/StatisticsDonutTableChart.vue'
import StatisticsDonutChart from '@/@core/components/statistics-card/StatisticsDonutChart.vue'

// composition function
import useDashboard from './useDashboard'

export default {
  components: {
    StatisticsCardVerticalBorder,
    StatisticsDonutTableChart,
    StatisticsDonutChart,
  },
  setup() {
    const {
      userData,
      dateRangeOptions,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedRecordingHoursOptions,
      computedHighlightsCreatedOptions,
      computedSharesOptions,
      computedImpressionsOptions,
      computedOccupancyHoursOptions,
      computedActiveUsersOptions,
      computedNewUsersOptions,
      computedTotalUsersOptions,
      ageRangeData,
      genderData,

      organizationsOptions,
      facilitiesOptions,
      customOrganization,
      customFacility,

      t,
      fetchSummary,
      updateFilter,
      getFilterByModule,
    } = useDashboard()
    const { configObjFacility } = useSelectOptions()
    const vm = getCurrentInstance().proxy

    const computedDateFrom = computed(() => formatStdDate(dateFrom.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))
    const computedDateTo = computed(() => formatStdDate(dateTo.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))

    onMounted(async () => {
      const filtersStored = getFilterByModule('views-reports-fanaty')
      if (filtersStored.organization) customOrganization.value = filtersStored.organization
      if (filtersStored.facility) customFacility.value = filtersStored.facility
      if (filtersStored.custom) dateRangeFilter.value = filtersStored.custom
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo

      // await fetchSummary()
    })

    return {
      userData,

      dateRangeOptions,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedRecordingHoursOptions,
      computedHighlightsCreatedOptions,
      computedSharesOptions,
      computedImpressionsOptions,
      computedOccupancyHoursOptions,
      computedActiveUsersOptions,
      computedNewUsersOptions,
      computedTotalUsersOptions,
      ageRangeData,
      genderData,

      computedDateFrom,
      computedDateTo,
      configObjFacility,

      organizationsOptions,
      facilitiesOptions,
      customOrganization,
      customFacility,
      formatStdDate,

      icons: {
        mdiCalendarArrowLeft,
        mdiCalendarArrowRight,
        mdiHelp,
        mdiWalletOutline,
      },

      // i18n
      t,

      avatarText,
      formatCurrency,

      fetchSummary,
      updateFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>
