<template>
  <v-card>
    <v-card-title class="align-start">
      <span> {{ title }}</span>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="showBtnDots"
              x-small
              icon
              class="me-n3 mt-n1"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="elevation-3"
              rounded
              size="40"
              :color="`${color}25`"
            >
              <v-icon
                size="30"
                :color="color"
              >
                {{ icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                {{ tableTitle }}
              </p>
              <span class="text--primary font-weight-semibold text-xl">{{ total }}</span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr
              v-for="(row, rowIndex) in items"
              :key="rowIndex"
            >
              <td
                v-for="(item, index) in row"
                :key="index"
              >
                <div class="mb-0">
                  <div
                    class="stats-dot d-inline-block rounded-circle me-2"
                    :style="`background-color: ${color}`"
                  ></div>
                  <span>{{ item.text }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ item.value.toLocaleString() }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiHelpCircleOutline, mdiWalletOutline } from '@mdi/js'
import { generateGradient } from '@core/utils'

export default {
  name: 'StatisticsDonutTableChart',
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    title: {
      type: String,
      default: 'Sales Overview',
      required: false,
    },
    tooltip: {
      type: String,
      default: 'tooltip',
      required: false,
    },
    chartData: {
      type: Array,
      default: () => [],
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
      required: true,
    },
    color: {
      type: String,
      default: '#16B1FF',
      required: false,
    },
    tableTitle: {
      type: String,
      default: 'Number of Sales',
      required: false,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    total: {
      type: [String, Number],
      default: '$86,400',
      required: false,
    },
    items: {
      type: Array,
      default: () => [
        [
          { text: 'Apparel', value: 12 },
          { text: 'Electronics', value: 24.5 },
        ],
        [
          { text: 'FMCG', value: 15 },
          { text: 'Other Sales', value: 50 },
        ],
      ],
      required: false,
    },
    showBtnDots: { type: Boolean, default: true },
  },
  setup(props) {
    const chartOptions = computed(() => {
      const options = {
        labels: props.labels,
        colors: generateGradient(props.color, props.labels.length || 10),
        chart: {
          type: 'donut',
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
              labels: {
                show: true,
                name: {
                  fontSize: '14px',
                  offsetY: 25,
                },
                value: {
                  fontSize: '2.125rem',
                  fontWeight: 600,

                  offsetY: -15,
                  formatter(value) {
                    return `${value}`
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: 'rgba(94, 86, 105, 0.68)',
                  formatter(value) {
                    return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                  },
                },
              },
            },
          },
        },
      }

      return options
    })

    return {
      chartOptions,
      icons: {
        mdiHelpCircleOutline,
        mdiWalletOutline,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
